import {React,Fragment,useState} from "react";
import './KindleDetail.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import trashIcon from "../../../assets/icons/Delete - Line icon.svg";
import filledIcon from "../../../assets/icons/filled_icon.svg";
import sourceLink from "../../../assets/icons/external_link_icon.svg";
import copyIcon from "../../../assets/icons/copyIcon.svg";
import linkIcon from "../../../assets/icons/cardLink.svg";
import shareIcon from "../../../assets/icons/shareLink.svg";
import Calender from "../../../assets/icons/Kindle - Calendar.svg";
import Kindleimage from "../../../assets/images/Kindle - Image 1.png";
import {faArrowUp,faStar} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import axios from "axios";
import { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Tick from "../../../assets/icons/Kindle - Tick.svg"
import amazonkindle from"../../../assets/images/Amazon kindle svg logo.svg"
import {faChevronDown} from "@fortawesome/free-solid-svg-icons";
import { Menu, Transition } from '@headlessui/react';
function    KindleDetail() {
const [isLoading, setIsLoading] = useState(false);
const [kindleCreated, setKindleCreated] = useState(false);
const [kindle, setKindleDetail] = useState([]);
const kindleId = useParams();
const [uniqueColors, setUniqueColors] = useState([]);
const [cardFilterDefault, setCardFilterDefault] = useState('Newest_Highlights');
const [cardLabelDefault, setLabelFilterDefault] = useState('Newest Highlights');

const filterDropDown = [
    { name: 'Newest Highlights', href: '#' },
    { name: 'Oldest Highlights', href: '#' }
]
// console.log(kindleId.id, "kindleId");

useEffect(() => {
   // Function to fetch data from the API and update the state
   const token = localStorage.getItem("token");
   
   const fetchHighlights = async () => {
    setIsLoading(true);
     try {
        const response = await axios.get(
            `${process.env.REACT_APP_API}books/highlights-in-book?bookId=${kindleId.id}&filterValue=${cardFilterDefault}`,  
            {
              headers: {
                Authorization: `Bearer ${token}`, // Include the token in the request header
              },
            }
          ); // Replace this with the actual API endpoint
       setIsLoading(false);
       // setKindle(response?.data?.data); // Assuming the API response is an array of bucket objects
       setKindleDetail(response?.data?.data);
       const colors = response?.data?.data?.bookHighlights.map(highlight => highlight.color);
       const uniqueColors = [...new Set(colors)];
       // Set the unique colors in state
       setUniqueColors(uniqueColors);
     } catch (error) {
        setIsLoading(false);
       console.error("Error fetching data:", error);
     }
   };
   if (token) {
      fetchHighlights();
   }
 }, [kindleCreated]);
 console.log(uniqueColors,"uniqueColors");
 const handleFilterText = (text) => {
    console.log(text, "handlefiltertext");
    setIsLoading(true);
    setLabelFilterDefault(text);
    // setFiltersText(text);
    const filterValues =
      text == "Newest Highlights"
        ? "Newest_Highlights"
        : text == "Oldest Highlights"
        ? "Oldest_Highlights"
        : "";
    // localStorage.setItem("cardFilter", filterValues);
    setCardFilterDefault(filterValues);
    cardUpdate();
    }
    function cardUpdate() {
        setKindleCreated(!kindleCreated);
    }
    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }
return(
    <>
    {isLoading ? (
        <div className="App-loading">
          <div className="App-logo" title="Zimzee" />
        </div>
      ) : (
      <>
      <div className="kindle-detail kindle-detail-desktop">
          <div className="flex justify-between mb-3">
            <div className="flex items-center justify-end"> 
              <h5><span className="project-nav">Kindle Highlights</span></h5><span className="kindlebg-content background-primary text-white rounded-full ml-4">{kindle?.bookHighlights?.length ? kindle?.bookHighlights?.length : 0}</span>
          </div>
          <div className="ml-auto">
                  <div className="ml-[auto] flex items-center justify-end">
                    <span className="sorttext-content mx-3 dark:text-[#acacac]">Sort By</span>
                    <Menu as="div" className="relative flex-shrink-0">
                      <div>
                        <Menu.Button className="flex rounded-full focus:outline-none ">
                          <div href="#" className="flex-shrink-0 rounded-full text-gray-400 hover:text-gray-500">
                            <strong className="createdtext-content pr-4">{cardLabelDefault}</strong>
                            <FontAwesomeIcon style={{fontSize:'12px',color:'#707070'}} icon={faChevronDown} />
                          </div>
                        </Menu.Button>
                      </div>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">                           
                          {filterDropDown.map((item) => (
                            <Menu.Item key={item.name}>
                              {({ active }) => (
                                <a
                                  onClick={()=>{handleFilterText(item.name)}}
                                  href={item.href}
                                  className={classNames(
                                    active ? 'bg-gray-100' : '',
                                    'block py-2 px-4 text-[13px] text-gray-700'
                                  )}
                                >
                                  
                                  {cardLabelDefault == item.name ? item.name : item.name}
                                </a>
                              )}
                            </Menu.Item>
                          ))}
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  </div> 
                </div>   
          </div>
          <div className="flex justify-between mb-3">
            <div className="flex items-center justify-end">
              <a href="/app/kindleList"><span className="project-nav">Kindle</span></a>&nbsp;<span className="project-nav">/</span>&nbsp;<a href={`/app/kindledetail/${kindleId.id}`} className="breadcrumbs-title"><span className="project-nav">{kindle?.bookDetail?.title}</span></a>
            </div>
          </div>
      <hr className="mt-[20px] mb-[30px]" />
      <div className="row w-3/3 flex "> 
          <div >
              <div className="kindle-card">
                  <h5>{kindle?.bookDetail?.title}</h5>
                  <h6>{kindle?.bookDetail?.authorName}</h6>
                  <div className="flex">
                  {/* <div className="mt-auto">
                  <span>Highlights</span>
                  </div> */}
                  {/* <div className="ml-auto">
                  <img src={amazonkindle} alt="Kindleimage"/>
                  </div> */}
                  </div>
                  <img className="my-[5px] kindle-image" src={kindle?.bookDetail?.image} alt="Kindleimage"/>
                  <div className="flex w-full">
                  {uniqueColors?.map((color,index) => {
                      return (
                          <>
                          <div className={`kindle-box bg-green ${uniqueColors.length == 1 ? "w-[100%]" : "w-[50%]"}`} style={{backgroundColor:color.trim().toLowerCase() == 'yellow' ? '#F2E366' : color, marginLeft: index > 0 ? "5px" : 0}}>
                              {/* <img className="kindle-tick" src={Tick} alt="Tick" /> */}
                          </div>
                          </>
                          );
                      })}
                      {/* <div className="kindle-box ml-[2px] bg-afc604 w-[50%]">
                          <img className="kindle-tick" src={Tick} alt="Tick" />

                          </div> */}
                  </div>
              </div>
          </div>
          <div className="w-3/3 ml-[24px]">
          {kindle?.bookHighlights?.map((kindle) => {
              return (
                  <div className="kindlecard" style={{borderTop:`14px solid ${kindle?.color.trim().toLowerCase() == 'yellow' ? '#F2E366' : kindle?.color}`}}>
                  <div className="menu-kindle">
                      <div className="flex actionsHolder items-center">
                      <p className="last-updated flex"><img className="mr-[11px]" src={Calender} alt="trashIcon"/>Added on {moment(kindle?.createdAt).format(
                          "MMM D, YYYY @ hh:mm A"
                        )} / Locations {kindle?.location}</p>
                          {/* <div>
                              <a href="#" className="flex items-center">
                              <FontAwesomeIcon className="transform rotate-45" icon={faArrowUp } />
                              <span className="actionicon-content pl-3">Move to</span>
                              </a>
                          </div> */}
                          {/* <div>
                              <a href="#" className="flex items-center pl-[14px]">
                              <img className="w-6 trash-icon" src={trashIcon} alt="trashIcon"/>
                              <span className="actionicon-content pl-3">Delete</span>
                              </a>
                          </div> */}
                          {/* <div>
                              <a href="#" className="flex items-center pl-[14px]">
                              <img className="w-6" src={filledIcon} alt="copy-to" />
                              <span className="actionicon-content pl-3">Copy to</span>
                              </a>
                          </div>
                          <div>
                              <a href="#" className="flex items-center pl-[14px]">
                              <img className="w-5" src={sourceLink} alt="source-link" />
                              <span className="actionicon-content pl-3">Open in Kindle</span>
                              </a>
                          </div> */}
                      </div>
                      <div className="sharedAction flex items-center">
                          {/* <div className="bg-[#FFE6D6] flex items-center px-3 py-1 rounded-2xl  mr-[10px]">
                              <p className="publicytext-content">Publicly Shared</p>
                              <span className="bg-white rounded-[50%] p-1 ml-2">
                              <img src={copyIcon} alt="copy-icon" />
                              </span>
                          </div> */}
                          <div className="w-6 mx-2">
                              <img className="cardicon-imgcontent menu-icon" src={linkIcon} alt="link-icon" />
                          </div>
                          {/* <div className="w-7 mx-2">
                              <img className="cardicon-imgcontent menu-icon" src={shareIcon} alt="shareIcon" />
                          </div> */}
                      </div>
                  </div>
                  <div className="mt-[10px]">
                      <p className="kindle-content">{kindle?.text}</p>
                  </div>
                  </div>
                  );
                  })}
          </div>
      </div>
      </div>
      <div className="kindle-detail kindle-detail-mobile">
          <div className="flex justify-between mb-3">
            <div className="flex items-center justify-end"> 
              <h5><span className="project-nav">Kindle Highlights</span></h5><span className="kindlebg-content background-primary text-white rounded-full ml-4">{kindle?.bookHighlights?.length ? kindle?.bookHighlights?.length : 0}</span>
            </div>
          </div>
          <div className="ml-auto">
                  <div className="ml-[auto] flex items-center justify-end">
                    <span className="sorttext-content mx-3 dark:text-[#acacac]">Sort By</span>
                    <Menu as="div" className="relative flex-shrink-0">
                      <div>
                        <Menu.Button className="flex rounded-full focus:outline-none ">
                          <div href="#" className="flex-shrink-0 rounded-full text-gray-400 hover:text-gray-500">
                            <strong className="createdtext-content pr-4">{cardLabelDefault}</strong>
                            <FontAwesomeIcon style={{fontSize:'12px',color:'#707070'}} icon={faChevronDown} />
                          </div>
                        </Menu.Button>
                      </div>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">                           
                          {filterDropDown.map((item) => (
                            <Menu.Item key={item.name}>
                              {({ active }) => (
                                <a
                                  onClick={()=>{handleFilterText(item.name)}}
                                  href={item.href}
                                  className={classNames(
                                    active ? 'bg-gray-100' : '',
                                    'block py-2 px-4 text-[13px] text-gray-700'
                                  )}
                                >
                                  
                                  {cardLabelDefault == item.name ? item.name : item.name}
                                </a>
                              )}
                            </Menu.Item>
                          ))}
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  </div> 
                </div>   
                <div className="flex justify-between mb-3">
                  <div className="flex items-center justify-end">
                    <a href="/app/kindleList"><span className="project-nav">Kindle</span></a>&nbsp;<span className="project-nav">/</span>&nbsp;<a href={`/app/kindledetail/${kindleId.id}`} className="breadcrumbs-title"><span className="project-nav">{kindle?.bookDetail?.title}</span></a>
                  </div>
                </div>
      <hr className="mt-[20px] mb-[30px]" />
      <div>
              <div className="kindle-card">
                  <h5>{kindle?.bookDetail?.title}</h5>
                  <h6>{kindle?.bookDetail?.authorName}</h6>
                  <div className="flex">
                  {/* <div className="mt-auto">
                  <span>Highlights</span>
                  </div> */}
                  {/* <div className="ml-auto">
                  <img src={amazonkindle} alt="Kindleimage"/>
                  </div> */}
                  </div>
                  <img className="my-[5px] kindle-image" src={kindle?.bookDetail?.image} alt="Kindleimage"/>
                  <div className="flex w-full">
                  {uniqueColors?.map((color,index) => {
                      return (
                          <>
                          <div className={`kindle-box bg-green ${uniqueColors.length == 1 ? "w-[100%]" : "w-[50%]"}`} style={{backgroundColor:color.trim().toLowerCase() == 'yellow' ? '#F2E366' : color, marginLeft: index > 0 ? "5px" : 0}}>
                              {/* <img className="kindle-tick" src={Tick} alt="Tick" /> */}
                          </div>
                          </>
                          );
                      })}
                      {/* <div className="kindle-box ml-[2px] bg-afc604 w-[50%]">
                          <img className="kindle-tick" src={Tick} alt="Tick" />

                          </div> */}
                  </div>
              </div>
          </div>
      <div className="row w-3/3 flex mt-5"> 
          <div className="w-3/3">
          {kindle?.bookHighlights?.map((kindle) => {
              return (
                  <div className="kindlecard" style={{borderTop:`14px solid ${kindle?.color.trim().toLowerCase() == 'yellow' ? '#F2E366' : kindle?.color}`}}>
                  <div className="menu-kindle">
                      <div className="flex actionsHolder items-center">
                      <p className="last-updated flex"><img className="mr-[11px]" src={Calender} alt="trashIcon"/>Added on {moment(kindle?.createdAt).format(
                          "MMM D, YYYY @ hh:mm A"
                        )} / Locations {kindle?.location}</p>
                          {/* <div>
                              <a href="#" className="flex items-center">
                              <FontAwesomeIcon className="transform rotate-45" icon={faArrowUp } />
                              <span className="actionicon-content pl-3">Move to</span>
                              </a>
                          </div> */}
                          {/* <div>
                              <a href="#" className="flex items-center pl-[14px]">
                              <img className="w-6 trash-icon" src={trashIcon} alt="trashIcon"/>
                              <span className="actionicon-content pl-3">Delete</span>
                              </a>
                          </div> */}
                          {/* <div>
                              <a href="#" className="flex items-center pl-[14px]">
                              <img className="w-6" src={filledIcon} alt="copy-to" />
                              <span className="actionicon-content pl-3">Copy to</span>
                              </a>
                          </div>
                          <div>
                              <a href="#" className="flex items-center pl-[14px]">
                              <img className="w-5" src={sourceLink} alt="source-link" />
                              <span className="actionicon-content pl-3">Open in Kindle</span>
                              </a>
                          </div> */}
                      </div>
                      <div className="sharedAction flex items-center">
                          {/* <div className="bg-[#FFE6D6] flex items-center px-3 py-1 rounded-2xl  mr-[10px]">
                              <p className="publicytext-content">Publicly Shared</p>
                              <span className="bg-white rounded-[50%] p-1 ml-2">
                              <img src={copyIcon} alt="copy-icon" />
                              </span>
                          </div> */}
                          <div className="w-6 mx-2">
                              <img className="cardicon-imgcontent menu-icon" src={linkIcon} alt="link-icon" />
                          </div>
                          {/* <div className="w-7 mx-2">
                              <img className="cardicon-imgcontent menu-icon" src={shareIcon} alt="shareIcon" />
                          </div> */}
                      </div>
                  </div>
                  <div className="mt-[10px]">
                      <p className="kindle-content">{kindle?.text}</p>
                  </div>
                  </div>
                  );
                  })}
          </div>
      </div>
      </div>
      </>
    )}
    </>
);
}
export default KindleDetail;